/*
Template dropdown styles
*/
.bootstrap-datetimepicker-widget.dropdown-menu{
    z-index: 5000!important;
    right:0!important;
    top:100%!important;
    left:auto!important;
}
.dropdown-menu.dropdown-menu-lg{
    width: 330px;
}
.dropdown-menu.dropdown-menu-right{
    right:0;
    left:auto;
}
.dropdown-menu{
    min-width: 160px;
    padding: .8rem;
    font-size:.85rem;
    color: inherit;
    border:0px;
    box-shadow:0 0px 5px 1px rgba($primary-active,.13);
    margin-top:1px;
    border-radius:5px;
    z-index:1500;
    color:rgba($text-heading,.85);
    .dropdown-divider{
        border-color:$border;
        margin:5px 0;
    }
    .dropdown-item{
        font-size:inherit;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        //text-transform: capitalize;
        display: block;
        padding: .45rem .9375rem;
        color:rgba($text-light,1);
        >i{
            width:auto;
            font-size: 10px;
            line-height: 1.5;
            margin-right: .5rem;
            display: inline-block;
            text-align: left;
            vertical-align: middle;
            position: relative;
            transition: all .3s;
            -webkit-transition: all .3s;
            margin-top: -2px;
            &[class^="icon-"], &[class*=" icon-"] {
                font-size: 16px;
                line-height:16px;
            }
        }
        &:hover,&:focus{
            background-color: rgba($light,1);
            color:$primary;
        }
    }
    .slimScrollBar{
        background-color: rgba($faded,1)!important;
        border-radius: 0px!important;
    }
    .list{
        .list-item{
            &:hover{
                background-color: rgba($light,1);
            }
            .list-body{
                &:after{
                    background-color:transparent;
                }
            }
        }
    }
    .list .list-item .list-thumb{
        border-radius:5px;
        -webkit-border-radius: 5px;
        overflow:hidden;
    }
}
.dropdown-menu.emoji-dropdown{
    width: 300px;
    height: 300px;
    table.table-light{
        background-color: #fff;
        td{
            text-align: center;
            border-top:0px;
            vertical-align: middle;
        }
    }
}
.dropdown-header-top{
    padding: .75rem .9rem;
    background-color: transparent;
}
.dropdown-footer{
    display: block;
    text-align: center;
    padding: .75rem .9rem;
    background-color: rgba($light,1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top:1px solid rgba($border,.5);
    &:hover{
        background-color: $light-active;
    }
    >i{
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;
    }
}
.dropdown-title{
    font-weight: 600;
}
.tool-item{
    display: block;
    line-height:1;
    >i{
        line-height: 1.2;
        color:$text-heading;
        font-size:24px;
    }
    .tool-heading{
        color:inherit;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 1.5;
        padding-top: 5px;
        opacity: .6;
        font-size: .8rem;
    }
}
.dropdown-toggle:after{
    position: relative;
    transition: all .3s;
    line-height:10px;
    width: 10px;
    font-size: 12px;
    height:10px;
    top:3px;
    border:0px;
    content: "\f0d7";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    text-align:center;
}
li.show .dropdown-toggle:after{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.dropdown-menu-dark{
    background-color: $dark;
    border-color: $dark-active;
    color:$text-light;
}
.dropup .dropdown-toggle:after{
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    border:0px;
    position: relative;
    transition: all .3s;
    line-height:10px;
    width: 10px;
    font-size: 12px;
    height:10px;
    top:3px;
    border:0px;
    content: "\f0d7";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    text-align:center;
}
.show.dropup .dropdown-toggle:after{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}


@media(min-width:992px){
    .dropdown-menu{
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
            -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transform-style: preserve-3d;
    }
    .show.dropdown-menu{
            -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
    }
    .navbar-light .dropdown-menu.dropdown-menu-lg{
        width: 568px;
    }
}
