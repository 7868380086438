/*
Layout aside mini
*/
.aside-icon-o{
    width: 4.5rem;
    min-height:100vh;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    z-index:600;
    transition:all .3s ease;
    -webkit-transition:all .3s ease;
    .sidenav-mini{
        width: 4.5rem;
        border: inherit;
        margin: 0;
        pointer-events: inherit;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height:100%;
    }
    .app-logo{
        width: 100%;
        overflow: hidden;
        position: relative;
        min-height: 3.5rem;
        background-color: $warning-light;
        border-bottom: 1px solid rgba($primary-active,.05);
        >i{
            font-size: 1.25rem;
            color: $primary;
        }
    }

}
.aside-mini-content{
    padding:1.5rem 0;
    .nav{
        .nav-item{
            display: block;
            width:100%;

            position: relative;
            .nav-link{
                display: block;
                flex-direction: row;
                padding: 0px;
                width: 100%;
                padding:.5rem 1.25rem;
                color:inherit;
                .badge{
                    position: absolute;
                    right: .5rem;
                    top: .5rem;
                    width: 16px;
                    height: 16px;
                    line-height: 16px;
                    font-size:.6rem;
                    font-weight: 600;
                }
                &:after{
                    display: table;
                    content: "";
                    clear: both;
                }
            }
            .nav-link>i{
                text-align: center;
                padding:0px;
                display: flex;
                width:2rem;
                height: 2rem;
                line-height: 1;
                font-size: 14px;
                -webkit-box-align: center!important;
                -ms-flex-align: center!important;
                align-items: center!important;
                -webkit-box-pack: center!important;
                -ms-flex-pack: center!important;
                justify-content: center!important;
                border-radius:10rem;
                transition: all .2s;
                -webkit-transition: all .2s;
            }
            .nav-link .nav-text{
                display: block;
                float:right;
                line-height: 2rem;
            }
        }
        .dropdown-menu{
            width:250px;
            border:0px;
            top:calc(100% - 3.5rem)!important;
            left: 100%!important;
            margin-left: .75rem;
            box-shadow: 0px 0px 10px rgba($primary,.12);
            -webkit-box-shadow: 0px 0px 50px rgba($primary,.12);
            &:before,&:after{
               	right: 100%;
                top:2rem;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &:after{
                border-color: rgba(136, 183, 213, 0);
                border-right-color: #fff;
                border-width: 10px;
                margin-top: -10px;
            }
            &:before{
                border-color: rgba(194, 225, 245, 0);
                border-right-color: #fff;
                border-width: 11px;
                margin-top: -11px;
            }
        }
        .dropup .dropdown-menu{
            top:auto!important;
            bottom:calc(100% - 4rem)!important;
            &:before,&:after{
                bottom: 1.5rem;
                top: auto;
            }
        }
        .dropdown-menu{
            display: block;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
            -webkit-transform: translateY(10px)!important;
            -moz-transform: translateY(10px)!important;
            -ms-transform: translateY(10px)!important;
            -o-transform: translateY(10px)!important;
            transform: translateY(10px)!important;
            -webkit-transform-style: preserve-3d;
        }
        .show.dropdown-menu{
            -webkit-transform: translateY(0px)!important;
            -moz-transform: translateY(0px)!important;
            -ms-transform: translateY(0px)!important;
            -o-transform: translateY(0px)!important;
            transform: translateY(0px)!important;
            opacity: 1;
            visibility: visible;
        }

    }
    .dropup.dropdown-submenu .dropdown-menu{
        display:none;
        &:before,&:after{
            bottom:2rem;
        }
    }
}
.aside-icon-o.aside-light{
    background-color: #fff;
    box-shadow: 1px -10px 10px rgba($dark,.12);
    -webkit-box-shadow: 1px -10px 10px rgba($dark,.12);
    .aside-mini-content .nav .nav-item {
        .nav-link{
            >i{
                background-color: rgba($primary-light,.2);
                color:rgba($primary,.55)
            }
        }
        &.active,&.show,&:hover{
            a.nav-link>i{
                background: linear-gradient(180deg, $info, $primary);
                background: -webkit-linear-gradient(180deg, $info, $primary);
                color:#fff;
            }
        }
    }
}
.aside-icon-o.aside-dark{
    background-color: $primary-active;
    box-shadow: 1px -10px 10px rgba($dark,.12);
    -webkit-box-shadow: 1px -10px 10px rgba($dark,.12);
    color: $white;
    .aside-mini-content .nav .nav-item {
        color:rgba($light,.5);
        .nav-link{
            >i{
                background-color: rgba($primary,.52);
                color:rgba($white,.75)
            }
        }
        &.active,&.show,&:hover{
            a.nav-link>i{
                background-color: $primary;
                color:#fff;
            }
        }
    }
        .app-logo{
            background-color: $primary;
                >i{
                    color: #fff;
                }
        }
}
@media(min-width:768px){
   .aside-icon-o .aside-mini-content .nav .nav-item .nav-link .nav-text{
        display: none;
    }
}
@media(max-width:991px){
    .layout-aside-mini{
        .page-wrapper{
            flex-flow: row!important; 
            align-content: start!important; 
        }
    }
}
@media(max-width:767px){
    .aside-icon-o{
        width: 13rem;
        position: fixed;
        padding-top:3.5rem;
        left:0;
        top:0;
        height: 100%;
        transform:translateX(-100%);
        -webkit-transform:translateX(-100%);
        -moz-transform:translateX(-100%);
        -ms-transform:translateX(-100%);
        transition:all .3s ease-in-out;
        -webkit-transition:all .3s ease-in-out;
        .sidenav-mini{
            width:13rem;
            height: calc(100% -3.5rem);
            overflow-y: auto;
            .app-logo{
                position: fixed;
                left:0;
                top:0;
                    -webkit-box-pack: start!important;
    -ms-flex-pack: start!important;
    justify-content: start!important;
    padding-left: 1.5rem;
            }
           
        }
        .aside-mini-content .nav .dropdown-menu{
            opacity: 1;
            display: none;
            visibility: visible;
            position: static!important;
            left:0!important;
            margin:0px;
            border-radius:0px;
            border:0px;
            width:100%!important;
            &:before,&:after{
                display: none;
            }
        }
        .aside-close{
            position: absolute;
            right: 1.5rem;
            top: 1rem;
            z-index:99;
            >i{
                font-size:24px;
            }
        }
         .badge{
                display: none!important;
            }
        .aside-mini-content .nav .show .dropdown-menu{
            display: block;
        }
        &.show{
             transform:translateX(0);
        -webkit-transform:translateX(0);
        -moz-transform:translateX(0);
        -ms-transform:translateX(0);
        }
    }
}