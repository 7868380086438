/*
Alert and notifications
*/
.close {
    position: absolute;
    padding: 0.65rem 1.25rem;
    text-shadow:none;
    color:inherit;
    right:5px!important;
    top:0px!important;
    &:hover{
        color: inherit;
        opacity:1;
    }
}
.alert{
    position:relative;
    .alert-link{
        color: inherit!important;
        font-weight: 600;
        border-bottom: 1px solid;
        border-bottom-color: rgba($dark,.1);
    }
    &.animated{
        -webkit-animation-duration: 1s!important;
        animation-duration: 1s!important;
        -webkit-animation-fill-mode: both!important;
        animation-fill-mode: both!important;
    }
    border-color: inherit;
   
    a{
        color:inherit;
        font-weight: 700;
        &:hover{
            color: inherit;
            text-decoration: underline;
        }
    }
    &.alert-primary{
        background-color: $primary-light;
        color: $primary-active;
        border-color: rgba($primary,.15); 
        hr{
            border-top-color: rgba($primary,.15); 
        }
    }
    &.alert-warning{
        background-color: $warning-light;
        color: $warning-active;
        border-color: rgba($warning,.15);   
         hr{
            border-top-color: rgba($warning,.15); 
        }
    }
    &.alert-success{
        background-color: $success-light;
        color: $success-active;
        border-color: rgba($success,.15);   
         hr{
            border-top-color: rgba($success,.15); 
        }
    }
    &.alert-danger{
        background-color: $danger-light;
        color: $danger-active;
        border-color: rgba($danger,.15);
         hr{
            border-top-color: rgba($danger,.15); 
        }
    }
    &.alert-info{
        background-color: $info-light;
        color: $info-active;
        border-color: rgba($info,.15);
       hr{
            border-top-color: rgba($info,.15); 
        }
    }
    &.alert-teal{
        background-color: $teal-light;
        color: $teal-active;
        border-color: rgba($teal,.15);
         hr{
            border-top-color: rgba($teal,.15); 
        }
    }
     &.alert-secondary{
        background-color: $secondary-light;
        color: $secondary-active;
        border-color: rgba($secondary,.15);
         hr{
            border-top-color: rgba($secondary,.15); 
        }
    }
    &.alert-light{
        background-color: $white;
        color: $text-body;
        border-color: rgba($border,1);
    }
    &.alert-dark{
        background-color: $faded;
        color: $dark-active;
        border-color: rgba($dark,.15);
         hr{
            border-top-color: rgba($dark,.15); 
        }
    }
    &.alert-purple{
        background-color: $purple-light;
        color: $purple-active;
        border-color: rgba($purple,.15);
         hr{
            border-top-color: rgba($purple,.15); 
        }
    }
    &.alert-pink{
        background-color: $pink-light;
        color: $pink-active;
        border-color: rgba($pink,.15);
         hr{
            border-top-color: rgba($pink,.15); 
        }
    }
}


/**notifications - bootstrap-notify**/
.alert[data-notify="container"]{
}
.alert {
    .progress{
        position: absolute;
        width: calc(100% - 2px);
        left:0;
        right:0;
        bottom: -1px;
        height: 2px;
        border-radius: 0;
        -webkit-border-radius: 0;
        background-color: transparent;
        >.progress-bar{
            background-color: $primary;
            border-radius: 0px;
            height: 2px;
            line-height: 2px;
            border-bottom-left-radius: .25rem;
        -webkit-border-bottom-right-radius:.25rem;
        }
    }
    &.alert-info{
        .progress>.progress-bar{
            background-color: $info;
        }
    }
     &.alert-success{
        .progress>.progress-bar{
            background-color: $success;
        }
    }
     &.alert-warning{
        .progress>.progress-bar{
            background-color: $warning;
        }
    }
     &.alert-danger{
        .progress>.progress-bar{
            background-color: $danger;
        }
    }
     &.alert-light{
        .progress>.progress-bar{
            background-color: $border;
        }
    }
     &.alert-primary{
        .progress>.progress-bar{
            background-color: $primary;
        }
    }
     &.alert-teal{
        .progress>.progress-bar{
            background-color: $teal;
        }
    }
     &.alert-purple{
        .progress>.progress-bar{
            background-color: $purple;
        }
    }
     &.alert-dark{
        .progress>.progress-bar{
            background-color: $dark;
        }
    }
     &.alert-pink{
        .progress>.progress-bar{
            background-color: $pink;
        }
    }
}
.table-demo-ui{
    tr,th,td,thead,tbody{
        vertical-align: middle;
    }
}
[data-notify="title"]{
    display:block;
    width:100%;
    font-weight: 500!important;
}
[data-notify="message"]{
    display:block;
    width:100%;
}
[data-notify="icon"].alert-thumb,.alert-thumb [data-notify="icon"]{
        float: left;
        display: inline-flex!important;
        margin-right: 0px;
        text-align:center;
        width: 2.5rem;
        height: 100%;
        line-height: 1;
        position: absolute;
        left:.5rem;
        top:-1px;
       -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important;
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important;
        overflow: hidden;
        font-size:16px;
        img{
            width: 90%;
            display:block;
            height: auto;
            position: relative;
            top: 1px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            -webkit-border-radius: 50%;
        }
}
.alert-thumb [data-notify="icon"] + *,[data-notify="icon"].alert-thumb + *,.alert-thumb [data-notify="icon"] + * + *,[data-notify="icon"].alert-thumb + * + *{
    padding-left:2rem;
}