/*
Accordions
*/
.card-accordions{
    .card,.card-header{
        border:0px;
        border-radius: 5px;
    }
    .card {
        .card-body{
            border-left: 1px solid $primary-light;
            border-right: 1px solid $primary-light;
            border-bottom: 1px solid $primary-light;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        .card-header{
            position: relative;
            padding: 0px;

            >.collapse-title{
                padding: .55rem 1.25rem;
                display: block;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                position: relative;
                background-color:$primary;
                font-weight: 500;
                font-size: .85rem;
                color:#fff;
                &.collapsed{
                    border-radius: 5px;
                    background-color: $light;
                    color:inherit;

                }

            }
        }
    }
}
.card-accordions-flat{
    .card,.card .card-header{
        border-radius: 0px;
        border:0px;
    }
    .card .card-body{
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    .card-header{
        position: relative;
        padding: 0px;
        >.collapse-title{
            &.collapsed{
                background-color: #fff;
                color:$text-heading;
                &:hover{
                    color: $primary;
                }
            }
            padding: .5rem 1.25rem;
            display: block;
            position: relative;
            background-color:#fff;
            font-weight: 500;
            font-size: .85rem;
            color:$primary;
            &:hover{
                color: $primary;
            }
        }
    }
}

.collapse-title{
    &:before{
        position: absolute;
        display: inline-block;
        transition: all .2s;
        -webkit-transition: all .2s;
        right: 1.25rem;
        top: 50%;
        height:12px;
        width:12px;
        text-align:center;
        line-height: 12px;
        margin-top: -4px;
        font-size: 16px;
              content: "\f107";
            font-family: 'Font Awesome 5 Free';
         transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        font-weight: 900;
    }  
    &.collapsed:before{
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
    }
}