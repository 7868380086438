/*
Dialogue card fixed at bottom
*/
.dialogue-card-btn{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
z-index: 1040;
padding:0px!important;
display:flex;
align-items:center;
justify-content:center;
width:54px;
height:54px;
line-height:1;
font-size:1rem;
>i{
font-size: 1.5rem;
transition: all .3s;
-webkit-transition: all .3s;
}
    &.active{
        >i:before{
               content: "\e773";
        }
}
}
.dialogue-card{
    position: fixed;
    z-index: 1039;
    right: 1rem;
    bottom: 7rem;
    width: 350px;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    transition: all .3s;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 21px 35px 0 rgba($primary,.15);
    opacity: 0;
    visibility: hidden;
    padding:0;
    display:flex;
    max-width:350px;
    flex:1;
    flex-direction:column;
    &.is-show{
        opacity: 1;
        visibility: visible;
         -webkit-transform: translateX(0px);
    transform: translateX(0px);
    }
    .dialogue-header{
        background-color: $primary;
        border-radius: 4px;
        height: 60px;
        color:#fff;
        box-shadow: 0px 2px 16px rgba($dark,.15);
            display: flex;
    flex-direction: row;
    align-items: center;
        .dialogue-title{
            color:#fff;
            font-size: 1rem;
            line-height: 1;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        >*{
            height: 100%;
           align-items: center;
           display: flex;
           ul{
               margin: 0;
               padding: 0;
               display: flex;
                   height: 100%;
                  margin-left:auto;
                  padding-right:1rem;
               >li{
                   display: flex;
                   height: 100%;
                       align-items: center;
               }
           }
        }
    }
    .dialogue-content{
        height: 300px;
        .slimScrollBar{
            width:5px;
            background: rgba($faded,1)!important;
        }
    }
    .dialogue-card-footer{
            -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: .5rem 1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    }
}
