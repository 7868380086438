/*
Carousel slider
*/

.carousel-item{
    .carousel-cpation{
        position: absolute;
        left:0;
        top:50%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items:center;
        transform: translateY(-50%);
    }
}
