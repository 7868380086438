/*
App calendar custom
*/
.fc-toolbar.fc-header-toolbar{
    margin-bottom: .5rem;
    h2{
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.5;
    }
}
.fc-button.fc-state-default{
  background-image: none!important;
background-color:transparent;
border:1px solid $border;
box-shadow:none;
//text-transform:capitalize;
&.fc-state-hover{
    background-color: $light;
}
&.fc-state-active{
    background-color: $light;
    box-shadow: inset 0px 0px 3px 0px rgba($dark,.05);
}
}
.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead{
    border-color: $border;
}
.fc-event{
    background-color: $primary;
    border-color:rgba( $primary-active,.5);
    border-radius: 0px;
    color:$primary-light!important;
    height: auto;
    padding: .5rem .5rem;
    font-weight: 500;
}
@media(max-width:476px){
    .fc-toolbar.fc-header-toolbar{
        h2{
            padding-top: 1rem;
        }
    }
}
